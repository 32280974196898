import { Box } from "@chakra-ui/react";
import React from "react";
import { secondary } from "../../../constants/colors";
import { Section } from "../../layout/Section";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Circle } from "../../visuals/Circle";
import { IntegratedServices } from "../../visuals/IntegratedServices";

export function Integration() {
  return (
    <TextAndVisual
      isVisualLeft
      isVisualSmall
      textPart={
        <BodyText>
          Our background lies in digitalising industrial and logistical processes.
          We are experts at building and connecting the automation that takes place
          in production, maintenance, warehouses and transportation to sales, billing and business
          analysis – allowing data to flow internally and giving you a solid base for
          decisions and efficient work.
        </BodyText>
      }
      visualPart={
        <Circle
          w={{
            base: "75%",
            lg: "100%"
          }}
          margin={{
            base: "auto",
            lg: 0
          }}
          bg={secondary}>
          <IntegratedServices />
        </Circle>
      }
    />)
}