import { Box, BoxProps } from "@chakra-ui/react"
import * as React from "react"

export interface FloatyServicesProps extends BoxProps {
  x?: string;
  y?: string;
}

export function FloatyServices(props: FloatyServicesProps) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="157 145 686 704"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".st0,.st19{fill:none;stroke:#000;stroke-width:10;stroke-miterlimit:10}.st19{stroke-width:8}"
        }
      </style>
      <path
        id="Path_150_00000114776067705640086850000013577012486946758833_"
        d="M500 164c188.75 0 341.77 153.02 341.77 341.77S688.75 847.54 500 847.54 158.23 694.53 158.23 505.77C158.23 317.02 311.25 164 500 164z"
        opacity={0.574}
        fill="#fdf7ed"
      />
      <path
        className="st0"
        d="M305.39 388.54c-8.47 0-15.65 5.51-18.17 13.14-1.47-.3-2.99-.46-4.55-.46-11.63 0-21.19 8.82-22.36 20.14-2.11-.5-4.31-.77-6.58-.77-15.85 0-28.7 12.85-28.7 28.7s12.85 28.7 28.7 28.7 28.7-12.85 28.7-28.7c0-1.05-.06-2.09-.17-3.12.14 0 .27.01.41.01 11.37 0 20.76-8.44 22.27-19.39.15 0 .3.01.46.01 10.57 0 19.14-8.57 19.14-19.14-.01-10.55-8.58-19.12-19.15-19.12zM345.47 428.3c-5.15.4-9.26 4.09-10.43 8.85-.91-.11-1.84-.14-2.79-.07-7.07.55-12.47 6.37-12.65 13.31-1.31-.2-2.66-.26-4.04-.15-9.64.75-16.85 9.18-16.1 18.82s9.18 16.85 18.82 16.1 16.85-9.18 16.1-18.82c-.05-.64-.14-1.27-.25-1.89.08-.01.17-.01.25-.01 6.91-.54 12.23-6.11 12.63-12.85.09-.01.19-.01.28-.01 6.43-.5 11.23-6.12 10.73-12.55-.51-6.43-6.12-11.23-12.55-10.73z"
      />
      <path
        className="st0"
        d="M225.02 603.85L225.02 501.05 260.81 501.05 260.81 598.55"
      />
      <path
        className="st0"
        d="M282.64 596.17L282.64 501.05 318.42 501.05 318.42 592.29"
      />
      <path
        className="st0"
        d="M398.78 724.54h-186.8V603.85c63.28-6.87 126.56-13.75 189.83-20.62-1 47.1-2.02 94.21-3.03 141.31z"
      />
      <path
        className="st0"
        d="M235.03 626.9H266.57V658.4399999999999H235.03z"
      />
      <path
        className="st0"
        d="M283.55 626.9H315.09000000000003V658.4399999999999H283.55z"
      />
      <path
        className="st0"
        d="M332.07 626.9H363.61V658.4399999999999H332.07z"
      />
      <g>
        <path
          transform="rotate(8.338 577.352 312.202)"
          className="st0"
          d="M394.29 177.59H760.69V446.98H394.29z"
        />
        <path
          transform="rotate(8.338 578.58 303.82)"
          className="st0"
          d="M395.51 203.39H761.91V404.40999999999997H395.51z"
        />
        <path
          transform="rotate(8.338 552.074 484.752)"
          className="st0"
          d="M522.4 445.14H582.01V524.61H522.4z"
        />
        <path
          transform="rotate(8.338 544.622 535.621)"
          className="st0"
          d="M463.52 524.07H625.97V547.44H463.52z"
        />
      </g>
      <g>
        <circle className="st19" cx={682.05} cy={762.51} r={29.82} />
        <circle className="st19" cx={682.05} cy={762.51} r={20.57} />
        <circle className="st19" cx={750.42} cy={755.94} r={29.82} />
        <circle className="st19" cx={750.42} cy={755.94} r={20.57} />
        <circle className="st19" cx={492.68} cy={780.7} r={29.82} />
        <circle className="st19" cx={492.68} cy={780.7} r={20.57} />
        <circle className="st19" cx={561.05} cy={774.13} r={29.82} />
        <circle className="st19" cx={561.05} cy={774.13} r={20.57} />
        <path
          transform="rotate(174.502 558.34 654.852)"
          className="st19"
          d="M442.5 597.61H674.17V712.08H442.5z"
        />
        <path
          className="st19"
          d="M465.52 756.58L446.44 758.42 443.83 731.29 695.02 707.16 686.68 620.34 730.81 616.1 781.63 681.59 785.87 725.81 774.84 726.87"
        />
        <path className="st19" d="M534.01 750.01L514.7 751.86" />
        <path className="st19" d="M656.26 738.26L585.48 745.06" />
        <path className="st19" d="M723.86 731.77L704.06 733.67" />
        <path
          className="st19"
          d="M726.64 630.74L767.53 682.94 719.61 687.55 714.27 631.93z"
        />
      </g>
    </Box>
  )
}
