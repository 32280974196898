import { Box, BoxProps } from "@chakra-ui/react"
import * as React from "react"

export function NetworkCircle(props: BoxProps) {
  const { children, ...chakra } = props;
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="80 72 842 855"
      xmlSpace="preserve"
      {...chakra}
    >
      <style />
      <path d="M502.5 910.05c-56.5 0-111.3-11.07-162.9-32.89-49.84-21.08-94.59-51.25-133.02-89.69-38.43-38.43-68.61-83.19-89.69-133.02C95.07 602.85 84 548.04 84 491.55s11.07-111.3 32.89-162.9c21.08-49.84 51.25-94.59 89.69-133.02 38.43-38.43 83.19-68.61 133.02-89.69C391.2 84.11 446 73.05 502.5 73.05s111.3 11.07 162.9 32.89c49.84 21.08 94.59 51.25 133.02 89.69 38.43 38.43 68.61 83.19 89.69 133.02 21.82 51.6 32.89 106.41 32.89 162.9s-11.07 111.3-32.89 162.9c-21.08 49.84-51.25 94.59-89.69 133.02-38.43 38.43-83.19 68.61-133.02 89.69-51.6 21.82-106.4 32.89-162.9 32.89zm0-827c-55.15 0-108.65 10.8-159 32.1-48.64 20.57-92.33 50.03-129.85 87.55s-66.97 81.2-87.55 129.85C104.8 382.9 94 436.4 94 491.55s10.8 108.65 32.1 159c20.57 48.64 50.03 92.33 87.55 129.85s81.2 66.97 129.85 87.55c50.36 21.3 103.86 32.1 159 32.1s108.65-10.8 159-32.1c48.64-20.57 92.33-50.03 129.85-87.55s66.97-81.2 87.55-129.85c21.3-50.36 32.1-103.86 32.1-159s-10.8-108.65-32.1-159c-20.57-48.64-50.03-92.33-87.55-129.85s-81.21-66.97-129.85-87.55c-50.35-21.3-103.85-32.1-159-32.1z" />
      <path d="M279.78 142.11c1.21-9.56 4.85-17.8 10.92-24.71 6.06-6.9 12.19-11.69 18.39-14.37 6.19-2.67 11.22-4.23 15.09-4.7 3.86-.47 7.07.42 9.62 2.66 4.2 3.69 5.29 10.17 3.28 19.43-2.01 9.26-4.87 16-8.55 20.2-6.85 7.81-14.97 11.52-24.33 11.14-9.38-.37-17.52-3.59-24.42-9.65zM706.01 142.11c-9.42 2.06-18.4 1.38-26.94-2.02-8.53-3.4-15.1-7.57-19.69-12.52-4.59-4.94-7.75-9.16-9.48-12.65-1.73-3.48-1.97-6.8-.71-9.96 2.07-5.19 7.81-8.39 17.21-9.6 9.4-1.2 16.71-.77 21.9 1.3 9.65 3.84 15.87 10.25 18.64 19.2 2.78 8.97 2.47 17.72-.93 26.25zM902.69 412c-8.11-5.21-13.99-12.03-17.62-20.47-3.63-8.44-5.32-16.03-5.07-22.77.25-6.74 1-11.95 2.24-15.64 1.24-3.68 3.41-6.2 6.54-7.55 5.14-2.21 11.45-.41 18.95 5.38 7.5 5.8 12.36 11.27 14.57 16.4 4.11 9.54 3.98 18.46-.39 26.76-4.37 8.29-10.78 14.26-19.22 17.89zM793.25 790.46c-3.13-9.12-3.5-18.11-1.11-26.99 2.39-8.87 5.77-15.87 10.16-21 4.38-5.13 8.2-8.75 11.47-10.88 3.26-2.12 6.53-2.74 9.81-1.86 5.4 1.45 9.24 6.79 11.52 15.98 2.28 9.2 2.69 16.51 1.24 21.9-2.7 10.03-8.34 16.95-16.92 20.74-8.57 3.8-17.3 4.5-26.17 2.11zM534.95 908.4c-6.2 7.38-13.72 12.33-22.56 14.85-8.83 2.52-16.58 3.23-23.24 2.11-6.65-1.11-11.73-2.52-15.23-4.23-3.49-1.7-5.71-4.18-6.64-7.45-1.53-5.38 1.06-11.41 7.77-18.11 6.71-6.69 12.76-10.81 18.14-12.34 9.99-2.85 18.82-1.57 26.49 3.82 7.66 5.39 12.75 12.51 15.27 21.35zM186.48 776.6c-9.34-2.37-17.07-6.98-23.19-13.84-6.12-6.85-10.12-13.52-12.02-20-1.9-6.47-2.84-11.66-2.83-15.55.01-3.89 1.28-6.96 3.81-9.23 4.17-3.72 10.73-4.02 19.68-.89 8.95 3.13 15.29 6.78 19.01 10.95 6.92 7.75 9.62 16.26 8.1 25.51-1.51 9.25-5.7 16.94-12.56 23.05zM84.29 401.85c-3.01-9.16-3.26-18.16-.76-27s5.98-15.8 10.43-20.87c4.44-5.07 8.32-8.65 11.61-10.73 3.28-2.08 6.56-2.66 9.83-1.73 5.38 1.52 9.15 6.91 11.31 16.13 2.16 9.23 2.48 16.54.96 21.92-2.83 10-8.56 16.84-17.18 20.51-8.63 3.69-17.36 4.27-26.2 1.77z" />
      {children}
    </Box>
  )
}

