import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { FloatyServices } from "./FloatyServices";
import { NetworkCircle } from "./NetworkCircle";

export function FloatyServicesInsideNetworkCircle(props: BoxProps) {
  return (
    <Box {...props}>
      <Box position="relative">
        <NetworkCircle position="absolute" />
        <FloatyServices position="absolute" width="75%" left="13%" pt="10%" />
      </Box>
    </Box>
  )
}