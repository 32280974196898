import { Box, BoxProps } from "@chakra-ui/react"
import * as React from "react"

export function Speechbubbles(props: BoxProps) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      w="100%"
      viewBox="127 120 744 767"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:10px}"
          }
        </style>
      </defs>
      <path
        className="cls-1"
        d="M862.71 317.31c0-106.12-103.62-192.14-231.45-192.14S399.81 211.2 399.81 317.31s103.62 192.14 231.45 192.14l205.25 2.18V406.16c16.73-26.58 26.2-56.8 26.2-88.86zM136.71 680.86c0-106.12 103.62-192.14 231.45-192.14 127.82 0 231.45 86.03 231.45 192.14C599.61 786.98 495.99 873 368.16 873l-205.25 2.18V769.71c-16.73-26.58-26.2-56.8-26.2-88.86z"
      />
    </Box>
  )
}

