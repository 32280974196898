import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import { ServicesHero } from "../components/sections/services/ServicesHero";
import { ServicesSection } from "../components/sections/services/ServicesSection";

function ServicesPage() {
  return (
    <Layout colorScheme="secondary" heroSection={<ServicesHero />}>
      <ServicesSection />
    </Layout>
  );
}

export default ServicesPage;

export const Head = () => (
  <MetaTags
    title="Services"
    description="Smart, automated processes save companies resources and money on a daily basis.
    At Indevit, we design and optimise IT systems that create growth and
    competitive advantages, so that you and your team can focus on your core."
  />
);
