import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { CircularImage } from "../../visuals/CircularImage";

export function Transparency() {
  return (
    <TextAndVisual
      textPart={
        <BodyText>
          Being communicative also means being open and transparent.
          Thanks to our long experience in the field, we quickly grasp 
          the scope of software projects. Our team is flexible and agile.
          We are skilled in visualising solutions, which we deploy 
          continuously – all so that you can draw the benefits from
          our work sooner.
        </BodyText>
      }
      visualPart={
        <CircularImage
          adornment="bottom"
          marginX={{
            base: "20px"
          }}
          maxW={{
            lg: "450px",
          }}
        >
          <StaticImage
            src="../../../images/christoffer_and_jonathan_working.jpg"
            alt="Christoffer and Jonathan working"
            placeholder="blurred"
          />
        </CircularImage>
      }
    />)
}