import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { HandHoldingServices } from "../../visuals/HandHoldingServices";

export function ServicesHero() {

  return (
    <Flex
      as="section"
      display="flex"
      w="100vw"
      flex="1 1 auto"
      flexDirection={{
        base: "column-reverse",
        lg: "row"
      }}
      alignItems={{
        base: "normal",
        lg: "center"
      }}
      justifyContent={{
        base: "flex-end",
        lg: "center"
      }}
    >
      <Box
        pt={{
          base: "60px",
          lg: 0
        }}
        pb={{
          base: "12",
          lg: 0
        }}
        pl={{
          base: 2,
          lg: "calc(calc(100% - 900px) / 2)",
          xl: "calc(calc(100% - 1024px) / 2)",
          "2xl": "calc(calc(100% - 1280px) / 2)",
        }}
        pr={{
          base: 2,
          lg: 8,
          "2xl": 16
        }}
        mt={{
          lg: "-50px"
        }}
        flex={{
          base: "0 0 auto",
          lg: "1 1 25%"
        }}
      >
        <Hero
          mb="6"
          textAlign={{
            base: "center",
            lg: "left"
          }}>IT solutions that create growth and competitive advantages</Hero>
        <BodyText
          textAlign={{
            base: "center",
            lg: "left"
          }}>
          Smart, automated processes save companies resources and money on a daily basis.
          At Indevit, we design and optimise IT systems that create growth and
          competitive advantages, so that you and your team can focus on your core.
        </BodyText>
      </Box>
      <HandHoldingServices
        flex={{
          base: "0 0 auto",
          lg: "1 1 35%",
          "2xl": "1 1 15%"
        }}
        pt={{
          base: "30px",
          lg: 0
        }}
        ml={{
          base: "20px",
          lg: "0"
        }}
      />
    </Flex >
  )
}