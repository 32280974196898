import React from "react";
import { Section } from "../../layout/Section";
import { Integration } from "./Integration";
import { Communication } from "./Communication";
import { Stack } from "@chakra-ui/react";
import { Transparency } from "./Transparency";

export function ServicesSection() {
  return (
    <Section>
      <Stack spacing="16">
        <Integration />
        <Communication />
        <Transparency />
      </Stack>
    </Section>
  );
}
