import React from "react";
import { primary, secondary } from "../../../constants/colors";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Circle } from "../../visuals/Circle";
import { Speechbubbles } from "../../visuals/Speechbubbles";

export function Communication() {
  return (
    <TextAndVisual
      isVisualLeft
      isVisualSmall
      textPart={
        <BodyText>
          We pride ourselves in being a group of communicative IT nerds. An
          oxymoron, you say? Maybe, but we are easy to talk to and get a hold of
          – in fact, all of our team is. Important pieces of information should
          not get lost in translation between the end user and the programmer,
          which is why we make sure they talk to each other often in our
          projects. When working with us, you get access to our entire hivemind,
          and if you want to, we can physically work from your premises, so that
          you can pick our brains in real time.
        </BodyText>
      }
      visualPart={
        <Circle
          w={{
            base: "50%",
            lg: "75%",
          }}
          margin={{
            base: "auto",
            lg: 0,
          }}
          bg={secondary}
        >
          <Speechbubbles />
        </Circle>
      }
    />
  );
}
