import { Box, BoxProps } from "@chakra-ui/react"
import * as React from "react"

export function IntegratedServices(props: BoxProps) {
  return (
    <Box
      as="svg"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".st0,.st20{fill:none;stroke:#000;stroke-width:10;stroke-miterlimit:10}.st20{stroke-width:7}"
        }
      </style>
      <circle className="st20" cx={843.56} cy={847.03} r={20.82} />
      <circle className="st20" cx={843.56} cy={847.03} r={14.36} />
      <circle className="st20" cx={891.5} cy={847.03} r={20.82} />
      <circle className="st20" cx={891.5} cy={847.03} r={14.36} />
      <circle className="st20" cx={710.74} cy={847.03} r={20.82} />
      <circle className="st20" cx={710.74} cy={847.03} r={14.36} />
      <circle className="st20" cx={758.69} cy={847.03} r={20.82} />
      <circle className="st20" cx={758.69} cy={847.03} r={14.36} />
      <path
        transform="rotate(-180 764.764 763.958)"
        className="st20"
        d="M683.9 724H845.63V803.92H683.9z"
      />
      <path
        className="st20"
        d="M693.48 828.46L680.09 828.46 680.09 809.43 856.26 809.43 856.26 748.55 887.21 748.55 918.16 797.45 918.16 828.46 910.42 828.46"
      />
      <path className="st20" d="M741.51 828.46L727.97 828.46" />
      <path className="st20" d="M827.25 828.46L777.61 828.46" />
      <path className="st20" d="M874.66 828.46L860.78 828.46" />
      <path
        className="st20"
        d="M883.34 758.44L908.27 797.45 874.66 797.45 874.66 758.44z"
      />
      <g>
        <path className="st0" d="M162.17 666.77L137.35 666.77" />
        <path
          className="st0"
          d="M128.19 516.44L128.19 127.25 861.99 127.25 861.99 666.77 202.86 666.77"
        />
        <path className="st0" d="M128.19 606.03L128.19 581.68" />
        <path
          className="st0"
          d="M186.09 581c-16.77.11-33.53.23-50.3.34M128.19 516.44v-337.7h733.8v402.59l-644.9.66"
        />
        <path className="st0" d="M435.4 666.77H554.77V825.93H435.4z" />
        <path className="st0" d="M332.42 825.94H657.77V872.75H332.42z" />
      </g>
      <g>
        <path
          className="st0"
          d="M188.04 480c-9.63 0-17.8 6.26-20.66 14.94-1.67-.34-3.4-.52-5.18-.52-13.22 0-24.09 10.03-25.42 22.9-2.4-.56-4.91-.87-7.48-.87-18.02 0-32.63 14.61-32.63 32.63 0 18.02 14.61 32.63 32.63 32.63s32.63-14.61 32.63-32.63c0-1.2-.07-2.38-.2-3.55.16 0 .31.01.47.01 12.92 0 23.6-9.59 25.31-22.04.17 0 .35.01.52.01 12.02 0 21.76-9.74 21.76-21.76.01-12.01-9.73-21.75-21.75-21.75zM233.6 525.2c-5.86.46-10.53 4.65-11.86 10.07a15.2 15.2 0 00-3.17-.07c-8.04.63-14.18 7.24-14.38 15.13-1.49-.23-3.03-.3-4.59-.18-10.96.85-19.16 10.43-18.31 21.4s10.43 19.16 21.4 18.31c10.96-.85 19.16-10.43 18.31-21.4-.06-.73-.15-1.45-.29-2.15.1-.01.19-.01.28-.01 7.86-.61 13.9-6.95 14.36-14.61.11-.01.21-.01.32-.02 7.31-.57 12.77-6.96 12.2-14.26-.57-7.32-6.96-12.78-14.27-12.21z"
        />
        <path
          className="st0"
          d="M96.67 724.78L96.67 607.91 137.35 607.91 137.35 718.75"
        />
        <path
          className="st0"
          d="M162.17 716.06L162.17 607.91 202.86 607.91 202.86 711.64"
        />
        <path
          className="st0"
          d="M294.22 862H81.84V724.78c71.94-7.81 143.88-15.63 215.82-23.44-1.14 53.55-2.29 107.11-3.44 160.66z"
        />
        <path className="st0" d="M108.05 750.99H143.91V786.85H108.05z" />
        <path className="st0" d="M163.21 750.99H199.07V786.85H163.21z" />
        <path
          className="st0"
          d="M218.37 750.99H254.23000000000002V786.85H218.37z"
        />
      </g>
    </Box>
  )
}