import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { FloatyServicesInsideNetworkCircle } from "./FloatyServicesInsideNetworkCircle";

export function HandHoldingServices(props: BoxProps) {
  return (
    <Box {...props}>
      <Box position="relative" mt="20%">
        <StaticImage
          src="../../images/handHolding.png"
          alt="Realistic hand in black and white holding a minimalist image of our services"
          placeholder="none"
        />
        <FloatyServicesInsideNetworkCircle
          position="absolute"
          top="-40%"
          width="45%"
          left="30%"
        />
      </Box>
    </Box>
  );
}
